import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "writing-tests"
    }}>{`Writing tests`}</h1>
    <p>{`Because `}<strong parentName="p">{`Sigi`}</strong>{` is depending on `}<strong parentName="p">{`DI`}</strong>{`, writing tests for `}<strong parentName="p">{`EffectModule`}</strong>{` is very simple:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-recipes-writing-tests-wdvqr"
      }}>{`writing tests example`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-recipes-writing-tests-wdvqr?fontsize=14&hidenavigation=1&module=%2Fsrc%2F__tests__%2Fapp.module.spec.ts&previewwindow=tests&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-recipes-writing-tests" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      